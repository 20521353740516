import "./type";

import Add_style from './app_add_style';
const add_style = new Add_style();

class app_common {
    $: any
    tw: any
    Jump: any
    constructor($: any, tw: any, Jump: any) {
        this.$ = $;
        this.tw = tw;
        this.Jump = Jump;
    };
    Main() {
        app_common.global_header(this.$);
        app_common.menutoggle(this.$, this.tw);
        app_common.smooth(this.Jump);
        app_common.device_replace();
    }

    static global_header($: any): void {
        let scroll_num: number;
        let scroll_go: string = "scroll-change-state";
        let global_header: any;
        let flag: boolean = false;

        global_header = $(".global-menu-open");
        let main_comp = global_header;
        let scroll_header = () => {
            let main_comp_top = main_comp.innerHeight();
            let main_comp_num = Math.floor(main_comp_top);
            scroll_num = $(window).scrollTop();
            if (scroll_num > main_comp_num) {
                global_header!.addClass(scroll_go);
            } else {
                global_header!.removeClass(scroll_go);
            }
        }

        window.addEventListener("load", function () {
            flag = true;
            if (flag) {
                scroll_header();
            }
        });

        window.addEventListener("scroll", function () {
            if (flag) {
                flag = false;
                setTimeout(function () {
                    scroll_header();
                    flag = true;
                    return flag;
                }, 0);
            }

        });

    }

    static menutoggle($: any, tw: any) {
        const menu_toggle: any = document.getElementById("js-menu-toggle");
        const global_header_toggle: any = document.getElementById("global-header");
        const body_stop: any = document.getElementById("component-wrap");
        // const header_nextlayer = document.getElementsByClassName("header-compnents-nextlayer")[0];
        const mobail_header_compnents = document.getElementsByClassName("mobail-header-compnents")[0];
        const menu_overlay: any = document.getElementById("menu_overlay");
        const bg: string = "bg";
        const scaleup: string = "scaleup";


        menu_toggle.addEventListener("click", function () {
            if (menu_toggle.classList.contains("menu-close")) {
                menu_toggle.classList.remove("menu-close");

                menu_overlay.classList.remove(scaleup);
                header_link(["0", "visible"]);
                global_header_toggle.classList.remove("open");
                global_header_toggle.classList.remove("scroll-change-state");
                body_stop.classList.remove("fixation-top");
                body_stop.classList.remove(bg);
                mobail_header_compnents.classList.remove("show");
                stop();
            } else {
                menu_toggle.classList.add("menu-close");

                menu_overlay.classList.add(scaleup);
                header_link(["1", "visible"]);
                global_header_toggle.classList.add("open");
                global_header_toggle.classList.add("scroll-change-state");
                body_stop.classList.add("fixation-top");
                body_stop.classList.add(bg);
                mobail_header_compnents.classList.add("show");

                add_style.add_style_css(BODY, { top: "-" + scrollnum + "px" });
            }
        }, false);

        const BODY: any = document.getElementsByTagName("body")[0];

        let scrollnum: number;
        function onScroll(): number {
            return scrollnum = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
        }
        window.onscroll = onScroll;

        let stop = function (): void {
            let body_style = BODY.getAttribute("style").replace("top:", "");
            delete_element(body_style);
        }

        let delete_element = function (num: string): void {
            let re_px;
            let re_fix;
            let type_elm;

            if (typeof num === "string") {
                re_px = num.replace("px", "");
                re_fix = re_px.replace("-", "");
                type_elm = Number(re_fix);
                window.scrollTo(0, type_elm);
            }
        }

        let header_link = function ([state_opacity, state_visibility]: string[] | number[]) {
            let delay_list = $(".delay");
            let delay_len: number = delay_list.length;
            let u: number = 0;
            for (u; u < delay_len; u++) {
                tw.to(delay_list[u], 1.5, { opacity: state_opacity, visibility: state_visibility });
            }
        }

    }
    static smooth(Jump: any): void {
        let top_smooth = document.querySelector('#pagetop');
        top_smooth!.addEventListener('click', function (e) {
            e.preventDefault();
            Jump('html', {
                duration: 1000
            });
        });
    }

    static device_replace(): void {
        let mql: MediaQueryList = window.matchMedia('screen and (max-width: 750px)');//切り替わる時に一度だけ実行できる。window.matchMedia Internet Explorer 10
        let node: NodeListOf<Element> = document.querySelectorAll(".js-replace");//NodeList
        let $rep = [].slice.call(node);//NodeList ie未対応
        let flag: boolean = false;
        let type: string = "";
        let reg: RegExp = /(.*)(?:\.([^.]+$))/;

        function checkBreakPoint(mql: any): void {
            if (mql.matches) {
                // スマホ向け
                if (window.innerWidth <= 750) {
                    $rep.forEach(function (index: any) {
                        type = index.getAttribute("src").match(reg)[2];
                        index.setAttribute("src", index.getAttribute("src").replace("." + type, "_sp." + type));
                    });
                    flag = true;
                }
            } else {
                // PC向け
                if (flag == true) {
                    $rep.forEach(function (index: any) {
                        type = index.getAttribute("src").match(reg)[2];
                        index.setAttribute("src", index.getAttribute("src").replace("_sp." + type, "." + type));
                    });
                    flag = false;
                }

            }
        }

        // ブレイクポイントの瞬間に発火
        mql.addListener(checkBreakPoint);

        // 初回チェック
        checkBreakPoint(mql);

    }

    public scrolleventlib(Splitting: any, ScrollOut: any, tw: any) {
        Splitting({
            target: "[data-splitting]",
            by: "chars",
        });
        ScrollOut({
            once: true,
            cssProps: {
                visibleY: true
            },
            onShown: function (el: any) {
                el.classList.add("will_anime");
                //el.animate([{ opacity: 0 }, { opacity: 1 }], 1000);
                tw.to(el, 1.5, { opacity: 1 });
            }
        });

    }

    public js_slidedown($: any): void {
        let slide = $(".js_slidedown");
        let slidedwon: string = "slidedwon";
        slide.hover(function () {
            $(this).addClass(slidedwon);
        }, function () {
            $(this).removeClass(slidedwon);
        });
    }

    public keywordsearch($: any) {
        let keywordsearch_btn = $("#keywordsearch-btn");
        let searchbox = $(".searchbox");
        let on: string = "on";
        keywordsearch_btn.click(function () {
            if (searchbox.hasClass(on)) {
                searchbox.removeClass(on)
            } else {
                searchbox.addClass(on)
            }
        });
    }
    public is_page_scroll($: any) {
        let timer: any;
        const is_page_scroll_target = document.getElementsByClassName("pages-box")[0];
        const global_header = $("#global-header");
        const Bool: boolean = Boolean(is_page_scroll_target);
        let flag: boolean = false;
        let headerH: any;
        if (Bool) {
            flag = true;
        } else {
            clearInterval(timer);
        }

        timer = setInterval(function () {
            if (flag) {
                pagesScroll();
                clearInterval(timer);
            }
        }, 0);

        function pagesScroll() {
            const clicktarget = $('.pages-box a[href^="#"]');

            clicktarget.on("click", function () {
                let href: string | any = $(this).attr("href");
                let hash: string | any = href == "#" || href == "" ? 'html' : href;
                headerH = global_header.height();
                scrollToAnker(hash, headerH);
                return false;
            });
        }

        function scrollToAnker(hash: string | any, headerH: number) {
            console.log(headerH);
            let target: string | any = $(hash);
            let position: string | any = target.offset().top;
            $('body,html').stop().animate({ scrollTop: (position - headerH) }, 500);
        }
    }

    public scrollFade(_position:any): void {
        let $ = this.$;
        app_common.pagetopScrollHead(_position);
        app_common.pagetopScrollFoot(_position,$);
    }

    static pagetopScrollHead(_scroll:any): void {
        let pagetop = document.getElementById('pagetop');
        if(pagetop === null) return;
        if (_scroll > 200) {
            pagetop.classList.add('show');
        } else {
            pagetop.classList.remove('show');
        }
    }

    static pagetopScrollFoot(_scroll:any,$:any): void {
        let pagetop = document.getElementById('pagetop');
        let BODY = $('body');
        if(pagetop === null) return;
        let pagetopH = pagetop.offsetHeight;
        if(BODY.hasClass('pc')){
            pagetopH = 190;
        }
        let scrollHeight = $(document).height(); //ドキュメントの高さ
        let scrollPosition = $(window).height() + _scroll; //現在地
        let footHeight = $("#global-footer").innerHeight() - pagetopH; //footerの高さ（＝止めたい位置）
        if ( scrollHeight - scrollPosition  <= footHeight ) {
            pagetop.classList.add('footStop');
        } else { //それ以外の場合は
            pagetop.classList.remove('footStop');
        }
    }
}

export default app_common;