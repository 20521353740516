class app_selectbox {

    $: any;
    constructor($: any) {
        this.$ = $;
    };

    public app_selectbox_fun(): void {
        let select = document.querySelectorAll(".js-selectbox");
        let flg = false;
        if(select === null ){
            return;
        }

        if(flg === false){
            app_selectbox.selectbox_first_event(select);
            flg = true;
        }
        app_selectbox.selectbox_change_event(select);
    }

    // 初回のみ
    static selectbox_first_event(_select:any):void {
        let select_array = Array.prototype.slice.call(_select);
        const select_array_len = select_array.length;
        for(let n = 0; n <= select_array_len-1; n++){
            let option = select_array[n].selectedIndex;
            let option_text = select_array[n][ option ].innerHTML;
            let selectbox_cover = select_array[n].nextElementSibling;
            selectbox_cover.innerHTML = option_text;

            let subcategory = document.getElementsByClassName('selectbox-subcategory')[0];
            if(n == select_array_len-1) {

                if(subcategory === null || subcategory === undefined ){
                    return;
                }else {
                    app_selectbox.selectbox_category_event(subcategory,option);
                }
            }

        };
    }

    static selectbox_change_event(_select:any):void {
        let select_array = Array.prototype.slice.call(_select);
        const select_array_len = select_array.length;
        for(let n = 0; n <= select_array_len-1; n++){
            select_array[n].addEventListener("change", function(){
                let index =  this.selectedIndex;
                let option_text = this[ index ].innerHTML;
                let selectbox_cover = this.nextElementSibling;
                if(index == 0){
                    selectbox_cover.classList.remove('selected');
                }else {
                    selectbox_cover.classList.add('selected');
                }
                selectbox_cover.innerHTML = option_text;


                app_selectbox.selectbox_subcategory_check(this,index);

            },false);
        };
    }

    static selectbox_subcategory_check(_this:any,_index:number):void {
        let subcategory = document.getElementsByClassName('selectbox-subcategory')[0];
        if(subcategory === null || subcategory === undefined){
            return;
        }

        let selectboxClass = _this.classList;

        if ( selectboxClass.contains('selectbox-subcategory')) return;


        app_selectbox.selectbox_category_event(subcategory,_index);
        return;
    }

    static selectbox_category_event(_select:any,_index:number):void {
        // セレクトボックスの祖先要素
        let _select_parent = _select.parentNode.parentNode;
        if(_index == 0) {
            // _select.disabled = true;
            _select_parent.classList.add('disabled');
        }else {
            // _select.disabled = false;
            _select_parent.classList.remove('disabled');
        }
    }


}

export default app_selectbox;


