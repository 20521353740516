import "@babel/polyfill";
import "objectFitPolyfill";
import * as $ from "jquery";
import Jump from "jump.js";
import * as ScrollOut from "scroll-out";
import { TweenMax as tw } from 'gsap';


import M_header from "./components/app_header";
import G_footer from "./components/app_footer";
import App_common from "./components/app_common";
import App_img_direction from "./components/app_img_direction";

import Page_active from './components/pages_active';
import Page_scroll from './components/page_scroll';
// import Accodion from './components/acco';
import app_modal from "./components/app_modal";

import app_selectbox from "./components/app_selectbox";
import app_accordion from "./components/app_accordion";

const app_Page_scroll = new Page_scroll($);
const app_Page_active = new Page_active($);
const modile_header = new M_header($);
const global_footer = new G_footer($);

const app_common = new App_common($, tw, Jump);
const app_img_direction = new App_img_direction($);
const modal_window = new app_modal($);

const global_selectbox = new app_selectbox($);
const global_accordion = new app_accordion($);


// const app_acco = new Accodion();
// app_acco.acco($);

app_Page_scroll.is_page_scroll();

app_Page_active.activeFunc();



document.addEventListener("DOMContentLoaded", function () {
    app_common.Main();
    app_img_direction.img_direction_check($);
    app_common.is_page_scroll($);
    global_selectbox.app_selectbox_fun();
    global_accordion.app_accordion_fun($);
    if (window.innerWidth > 750) {
        app_common.keywordsearch($);
        app_common.js_slidedown($);
    } else {
        // app_acco.acco($);
    }
    let main = document.getElementsByTagName('main')[0];
    let data_set = main.getAttribute('data-page');
    if(data_set === 'outline'){
        ScrollOut({
            threshold: .25,
            once: true,
            onShown: function (el: any) {
                el.classList.add("animated");
            }
        });
    }
});


let header = document.getElementsByClassName('mobail-header-compnents')[0];
let h_clickEvent_target = header.querySelectorAll(".clickEvent");
let h_array = Array.prototype.slice.call(h_clickEvent_target);
const h_array_len = h_array.length;
for(let n = 0; n <= h_array_len-1; n++){
    h_array[n].addEventListener("click", function(){
        // e.preventDefault();
        modile_header.modile_header_fun(this);
    },false);
};

let footer = document.getElementsByClassName('global-footer-component')[0];
let f_clickEvent_target = footer.querySelectorAll(".clickEvent");
let f_array = Array.prototype.slice.call(f_clickEvent_target);
const f_array_len = f_array.length;
for(let n = 0; n <= f_array_len-1; n++){
    f_array[n].addEventListener("click", function(){
        // e.preventDefault();
        global_footer.global_footer_fun(this);
    },false);
};


window.addEventListener('scroll', ()=> {
    app_common.scrollFade(window.pageYOffset);
    $("#global-header").css("left", -$(window).scrollLeft());
});


let modalClickEvent_target:NodeListOf<Element> = document.querySelectorAll(".modal-open");
let modal_array = Array.prototype.slice.call(modalClickEvent_target);
const modal_array_len = modal_array.length;

for(let n = 0; n <= modal_array_len-1; n++){
    let src = $('.modal-open:eq('+n+')').find('img').attr('src');
    // no-image以外ならクリックイベントを設定
    if (src.indexOf('no-image.jpg') === -1) {
        modal_array[n].addEventListener("click", function(e:any){
            e.preventDefault();
            modal_window.modal_fun(this);
        },false);
    } else {
        $('.modal-open:eq('+n+')').addClass('no-img');
        $('.modal-open:eq('+n+')').css('cursor','default');
        $('.modal-open:eq('+n+')').parents('.property-top_slider').addClass('no-img');
        $('.modal-open:eq('+n+')').click(function(){
            return false;
        })
    }

};

//デベロップ事業のモーダル＆スライダー
let modalClickEvent_target_dev:NodeListOf<Element> = document.querySelectorAll(".modal-slider");
let modal_array_dev = Array.prototype.slice.call(modalClickEvent_target_dev);
const modal_array_len_dev = modal_array_dev.length;

for(let n = 0; n <= modal_array_len_dev-1; n++){
    modal_array_dev[n].addEventListener("click", function(e:any){
        e.preventDefault();
        modal_window.modal_fun(this);
    },false);
};

let modalCloseEvent_target = document.querySelectorAll(".modalCloseEvent");
let modalClose_array = Array.prototype.slice.call(modalCloseEvent_target);
const modalClose_array_len = modalClose_array.length;
for(let n = 0; n <= modalClose_array_len-1; n++){
    modalClose_array[n].addEventListener("click", function(e:any){
        e.preventDefault();
        modal_window.modal_close(this);
    },false);
};