interface def {
    this: string | Record<string, any>
}

class Is_page_scroll {
    constructor(public $: any) {
        this.$ = $
    }

    public is_page_scroll():void {
        const _that = this.$
        const clicktarget = _that('.scroll-btn')

        clicktarget.on('click', function(this: def) {
            const href: string | any = _that(this).attr('href')
            const hash: string | any = href == '#' || href == '' ? 'html' : href

            if(_that(this).hasClass('acd-open-link')) {
                let target=_that(hash);
                let target_content = target.find('.accordion_content')
                let target_inner = target_content.find('.inner');
                let target_innerH = target_inner.innerHeight();

                console.log(hash)
                target_content.css('height',target_innerH);


                _that(hash).addClass('open')
            }else {

            }
            scrollToAnker(hash)
            return false
        })

        function scrollToAnker(hash: string | any) {
            const target: string | any = _that(hash)
            const position: string | any = target.offset().top

            let headerH = _that('#global-header').outerHeight();


            _that('body,html').stop().animate({ scrollTop: position - headerH }, 500)
        }
    }

}
export default Is_page_scroll
