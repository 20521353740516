class app_img_direction {
    $: any
    constructor($: any) {
        this.$ = $;
    };

    public img_direction_check($:any): void {
        let swiper = document.getElementsByClassName('.swiper-container');
        let img_thumb = document.getElementsByClassName('.img_thumb');
        let img_thumb_property = $('.property_unit-thumb');
        if (swiper) {
            app_img_direction.swiper_img($);
        }
        if (img_thumb) {
            let img = $('.img_thumb').find('img');
            app_img_direction.img_size_check($,img);
        }
        if(img_thumb_property) {
            app_img_direction.img_size_check_thumb($);
        }
    }

    static swiper_img($:any):void {
        let swiper = $('.swiper-wrapper');
        if (!swiper) return;
        let swiper_img = swiper.find('img');
        app_img_direction.img_size_check($,swiper_img);
    }

    static img_size_check($:any,img:any):void {
        setTimeout(() => {
            img.each(function() {
                let img_w = $(this).width();
                let img_h = $(this).height();
                if((img_w / img_h) >  1.4375){
                    $(this).addClass('wider-img');
                }else if((img_w / img_h) <  1.4375){
                    $(this).addClass('taller-img');
                }
            });
        }, 500);
    }

    static img_size_check_thumb($:any):void {

        let img_thumb_property = $('.property_unit-thumb img');
        var img_thumb_propertyArray = Array.prototype.slice.call(img_thumb_property);
        var nnn = [];
        for(var h = 0; h <= img_thumb_propertyArray.length-1; h++){
            var mmm = img_thumb_propertyArray[h];
            nnn.push(mmm);
        }

        nnn.forEach(function(value:any,index:any) {
            setTimeout(() => {
                var wi = value.width;
                var he = value.height;
                var percent = wi/he;
                let ratio = 24/17;
                if(percent < ratio){
                    $(img_thumb_propertyArray[index]).addClass('taller-img');
                } else if(percent > ratio){
                    $(img_thumb_propertyArray[index]).addClass('wider-img');
                }
            }, 500);
        });
    }

}
export default app_img_direction;