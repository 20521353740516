
class Pages_active{
    $: any;
    constructor($:any){
        this.$ = $;
    }

    activeFunc(){
        const Location = location.pathname;
        const arr_location = Location.split("/");
        const x = arr_location.filter(function(v){ return v});
        let get_class_element = document.getElementsByClassName("pages-"+ x[0])[0];

        if(get_class_element){
            get_class_element.firstElementChild!.classList.add("active");
        }
    }
}
export default Pages_active;