class app_accordion {

    $: any;
    constructor($: any) {
        this.$ = $;
    };

    public app_accordion_fun($:any): void {
        let accordion_title = $('.accordion_title');
        let accordion_closebtn = $('.accordion_closebtn');
        if(accordion_title === null || accordion_title === undefined) return;
        accordion_title.on('click',function() {
            app_accordion.app_accordion_click($(this));
        });
        accordion_closebtn.on('click',function(e:any) {
            e.preventDefault();
            app_accordion.app_accordion_click($(this));
        });
    }

    static app_accordion_click(_this:any): void {
        let accordion_wrap = _this.parents('.accordion_wrap');

        let accordion_content = accordion_wrap.find('.accordion_content');
        let accordion_inner = accordion_content.find('.inner');
        let accordion_innerH = accordion_inner.innerHeight();

        if(accordion_wrap.hasClass('open')){
            accordion_wrap.removeClass('open');
            accordion_content.css('height','');

        }else if(!accordion_wrap.hasClass('open')){
            accordion_wrap.addClass('open');
            accordion_content.css('height',accordion_innerH);
        }
    }
}

export default app_accordion;


