class app_modal {

    $: any;
    constructor($: any) {
        this.$ = $;
    };

    public modal_fun(_that:any): void {
        let $ = this.$;
        let id = _that.getAttribute('data-id');
        if(id === null) return;
        app_modal.modal_type_check(_that,id,$);
    }

    static modal_type_check(_that: any,_id: any,$:any): void {
        if(_that.classList.contains('modal-open-img')){
            app_modal.modal_insert_img(_that,_id,$);
        }else if(_that.classList.contains('modal-open-movie')){
            app_modal.modal_insert_movie(_that,_id);
        }else {
            app_modal.modal_choice(_id);
        }
    }

    static modal_choice(_that:any): void {
        let modal = document.getElementById(_that);
        let modalbg = document.getElementsByClassName('modal-bg')[0];
        if(modal == null) return;
        if(modal!.classList.contains('open')){
            modal.classList.remove('open');
        }else{
            modal.classList.add('open');
            modalbg.classList.add('open');
        }
    }


    static modal_insert_img(_that:any,_id: any,$:any): void {
        let modal = document.getElementById(_id);
        if(modal === null) return;
        let img = $(_that).find('img');
        let img_src = img.attr('src');
        let imgWidth = img.width();
        let imgHeight = img.height();
        let img_class = "";
        if(imgWidth > imgHeight){
            img_class = 'wider-img';
        } else if(imgWidth <= imgHeight){
            img_class = 'taller-img';
        }
        let modal_img = '<img src="' + img_src + '" alt="">';
        modal.classList.remove('wider-img');
        modal.classList.remove('taller-img');
        modal.classList.add(img_class);
        $(modal).find('.modal-img').empty();
        $(modal).find('.modal-img').append(modal_img)
        app_modal.modal_choice(_id);
    }


    static modal_insert_movie(_that:any,_id: any): void {
        let modal = document.getElementById(_id);
        if(modal === null) return;
        let modal_iframe = document.getElementById('player');
        let youtube_id = _that.getAttribute('data-youtube');
        let youtube_src = 'https://www.youtube.com/embed/'+ youtube_id + '?rel=0';
        if(!modal_iframe || !youtube_id) return;

        modal_iframe.setAttribute('src','');
        modal_iframe.setAttribute('src',youtube_src);

        app_modal.modal_choice(_id);
    }

    public modal_close(_that:any): void {
        let $ = this.$;
        app_modal.modal_close_start(_that,$);
    }

    static modal_close_start(_that:any,$:any): void {
        let oya_modal = $(_that).parents('.modal-area').find('.modal.open');
        let modalbg = $('.modal-bg');
        if(oya_modal.hasClass('open')){
            app_modal.fadeout(oya_modal,modalbg);
        }
    }

    static fadeout(_that:any,_bg:any): void {
        _that.addClass('fadeout');
        _bg.addClass('fadeout');

        _that.on('animationend webkitAnimationEnd oAnimationEnd mozAnimationEnd',function(){
            app_modal.fadeoutEnd(_that,_bg);
        });
    }

    // アニメーションの終了を受け取る
    static fadeoutEnd(_that:any,_bg:any): void {
        app_modal.classRemoveOpen(_that,_bg);
        _that.removeClass('fadeout');
        _bg.removeClass('fadeout');
    }

    // openというクラスを外す
    static classRemoveOpen(_that:any,_bg:any): void {
        _that.removeClass('open');
        _bg.removeClass('open');
        if(_that.find('.youtube-iframe')){
            _that.find('.youtube-iframe').attr('src','');
        }
    }

}

export default app_modal;


