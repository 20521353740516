class app_footer {

    $: any;
    constructor($: any) {
        this.$ = $;
    };

    public global_footer_fun(_that:any): void {
        let $ = this.$;
        app_footer.menu_slide(_that,$);
    }

    static menu_slide(_that:any,$:any){
        let BODY = $('body');
        if(BODY.hasClass('pc')){
            return;
        }

        if ($(_that).hasClass('open-drop')) {
            $(_that).removeClass('open-drop');
            $(_that).next('.nav-drop').css('height', '');
        } else if (!$(_that).hasClass('open-drop')) {
            $(_that).addClass('open-drop');
            let windowWidth = window.innerWidth;
            if(windowWidth >767){
                $(_that).removeClass('open-drop');
                $(_that).next('.nav-drop').css('height', '');
            }
            let inrH: any = $(_that).next('.nav-drop').find('.nav-drop_list').outerHeight();
            $(_that).next('.nav-drop').css('height', inrH);
        }

    }

}

export default app_footer;


